import styled from "styled-components";

export const BoxPage = styled.div`
  background-color: #f8fafd;
  display: flex;
  flex-direction: column;
  font-family: "Inter", sans-serif;
  height: 100%;
  position: fixed;
  width: 100%;
  align-items: center;

  @media (max-width: 576px) {
    flex-direction: column;
    align-items: center;
  }
`;

export const Table = styled.table`
  border-collapse: collapse;
  width: 90%;
  font-family: "Inter", sans-serif;
  margin-top: 2%;
  display: inline-table;
  overflow: auto;

  @media (max-width: 576px) {
    display: block;
    overflow-x: auto;
    white-space: nowrap;
  }
`;

export const Thead = styled.thead`
  background-color: #f8fafd;
  border: none;
  border-bottom: 1px solid #ddd;
  color: #9e9e9e;
`;

export const Tr = styled.tr`
  font-size: 14px;

  @media (max-width: 576px) {
    display: block;
    margin-bottom: 10px;
  }

  ${(props) =>
    props.titulo === "titulo" &&
    `
    background-color: none;
  `}

  ${(props) =>
    props.tipo === "descricao" &&
    `
    display:none;`}

  ${(props) =>
    props.status === "ABERTO" &&
    `
    border-left: 5px solid #cd0000;
    background-color: #ffb2b2;

  `}

  ${(props) =>
    props.status === "ATENDIMENTO" &&
    `
    background-color: #fff;
    border-left: 5px solid #cd7100;
  `}
/*
  ${(props) =>
    props.status === "RESOLVIDO" &&
    `
    background-color: #319d17;
  `}

  ${(props) =>
    props.status === "CANCELADO" &&
    `
    background-color: #cd0000;
  `}
*/

  &:hover {
    background-color: #f8fafd;
  }
`;

export const Td = styled.td`
  padding: 8px;
  border-bottom: 1px solid #ddd;
  word-break: break-all;

  @media (max-width: 576px) {
    display: block;
  }

  ${(props) =>
    props.tipo === "setor" &&
    `
  font-weight: bold; 
  text-align:center;`}
`;

export const Tbody = styled.tbody`
  @media (max-width: 576px) {
    table-layout: auto;
    display: flex;
  }
`;

export const Th = styled.th`
  padding: 8px;
  border-bottom: 1px solid #ddd;

  ${(props) =>
    props.nomeTitulo === "Descrição" &&
    `
    display:none;`}

  ${(props) =>
    props.nomeTitulo === "Suporte" &&
    `
    display:none;`}

${(props) =>
    props.nomeTitulo === "Data" &&
    `
    display:none;`}
`;

export const Button = styled.button`
  background-color: ${(props) =>
    props.bgColor === "green" ? "EBEBEB" : "#EBEBEB"};
  color: ${(props) => (props.bgColor === "green" ? "#2e6930" : "#0A5987")};
  border: ${(props) =>
    props.bgColor === "green" ? "2px solid #2e6930" : "2px solid #4A8EB6"};
  border-radius: 0.4rem;
  height: 25px;
  width: 25px;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  transition: 0.4s;

  &:hover {
    background-color: ${(props) =>
      props.bgColor === "blue" ? "#0A5987" : "#2e6930"};
    color: ${(props) => (props.bgColor === "blue" ? "#EBEBEB" : "#EBEBEB")};
  }
`;

export const MenuButton = styled.button`
  background-color: transparent;
  color: ${(props) => (props.color === "black" ? "#000" : "#fff")};
  border: none;
  font-size: 16px;
  width: fit-content;
  height: fit-content;
  justify-content: center;
  align-items: center;
  transition: 0.4s;
  margin-left: 1%;

  &:hover {
    color: #0a5987;
  }
`;

export const Head = styled.div`
  display: flex;
  flex-direction: column;
  height: 15%;
  justify-content: center;
  align-items: center;
  width: 100%;
`;

export const Menu = styled.div`
  height: 45%;
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  background: #fff;
  box-shadow: 0px 4px 15px 0px rgba(0, 0, 0, 0);
`;

export const TitleContainer = styled.div`
  width: 100%;
  color: #ffffff;
  height: 3rem;
  padding: 0.3%;
  align-items: center;
  justify-content: center;
  text-align: center;
  font-size: 150%;

  background: linear-gradient(
    90deg,
    #229ecd -8%,
    #3aa25f 66.54%,
    #ff9f8b 126.36%
  );
  @media screen and (max-width: 576px) {
    font-size: medium;
  }
`;

export const Title = styled.h1`
  font-size: 1.5rem;
  color: white;
  font-family: "Inter", sans-serif;
  font-weight: bold;
  margin-top: 5px;

  @media (max-width: 576px) {
    margin-top: 9px;
    font-size: 1.2rem;
  }
`;

export const Logo = styled.img`
  width: 15%;
  height: auto;
  margin-left: 2%;
  margin-right: 2%;
`;

export const Footer = styled.footer`
  background-color: #0a5987;
  border: 1px solid #01436a;
  border-top: #3aa25f 10px solid;
  bottom: 0;
  color: #c8c8c8;
  display: flex;
  flex-direction: column;
  font-size: small;
  font-weight: lighter;
  height: 8%;
  justify-content: center;
  text-align: center;
  width: 100%;
`;

export const ResultadoBox = styled.div`
  width: 80%;
  height: 15%;
  flex-shrink: 0;
  border-radius: 5px;
  font-family: "Inter", sans-serif;
  border: 1px solid #e6e6e6;
  background: white;
  left: 0;
  display: flex;
  justify-content: space-around;

  @media (max-width: 576px) {
    display: none;
  }
`;

export const BoxChamado = ({ titulo, quantidade, ...props }) => {
  return (
    <div
      style={{
        color:
          props.type !== "saldoDoDia"
            ? "black"
            : props.sinal === "positivo"
            ? "#3AA25F"
            : props.sinal === "negativo"
            ? "#CD0000"
            : "#000",
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        alignItems: "center",
        width: "fit-content",
        fontFamily: "Inter, sans-serif",
      }}
    >
      <Resultado>
        <div>{quantidade}</div>
        <div>{titulo}</div>
      </Resultado>
    </div>
  );
};

export const Resultado = styled.div`
  flex-direction: column;
  display: flex;
  position: sticky;
  justify-content: center;
  align-items: center;
  font-family: "Inter", sans-serif;
  font-size: 100%;

  @media (max-width: 576px) {
    flex-wrap: wrap;
    font-size: 0.6rem;
  }
`;

export const Section = styled.div`
  width: 100vw;
  height: 100vh;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  display: flex;
  flex: 1 1 auto;
  max-height: calc(100vh - 180px);
  overflow-y: auto;
`;

export const BoxList = styled.div`
  width: 100vw;
  height: 33vh;
  flex-direction: row;
  justify-content: flex-end;
  flex-wrap: wrap;
  align-items: center;
  display: flex;
  padding: 2%;
`;

export const BoxAux = styled.div`
  padding: 1%;
  gap: 5px;
  display: flex;
  width: 15%;
  flex-wrap: wrap;
  justify-content: flex-start;
  @media (max-width: 576px) {
    display: none;
  }
`;

const Tabela = {
  ResultadoBox,
  Section,
  Table,
  BoxChamado,
  TitleContainer,
  BoxPage,
  Button,
  MenuButton,
  Thead,
  Tr,
  Th,
  Td,
  Tbody,
  Footer,
  Head,
  Logo,
  Menu,
  BoxList,
  BoxAux,
  Title,
};

export default Tabela;

